<template>
  <div class="dialogItem_r_phone">
    <BScroll
      :mouseWheel="mouseWheel"
      :scrollbar="scrollbar"
      :probeType="3"
      :bounce="false"
      ref="bscroll"
      style="overflow: hidden;padding:0 0.7rem;box-sizing:border-box;"
      class="dialogItem_r_phone_mn"
      :style="{'transform':'scale('+ ratio +', '+ ratio +') !important','height':'calc('+ H +')','width':'calc('+ W +')'}"
    >
      <div style="font-size:40px;font-weight:bold;margin-bottom:20px;margin-top:20px;">{{title}}</div>
      <div>
        <div ref="neirong"></div>
      </div>
    </BScroll>
  </div>
</template>

<script>
import BScroll from '../../../components/content/scroll/BScroll'
import { news } from '@/network/service'
import { newsdate } from '@/common/utils'
export default {
  name: 'news',
  data () {
    return {
      list: [],
      title: '',
      newsmsg: ``,
      ratio: 0,
      H: '',
      W: '',
      mouseWheel: {
        speed: 20,
        invert: false,
        easeTime: 300,
      },
      scrollbar: {
        fade: false,
        interactive: true,
      },
    }
  },
  components: { BScroll },
  mounted () {
    this.ratio = window.screen.width / 750
    this.newsPost()
  },
  methods: {
    async newsPost () {
      let date = newsdate()
      let res = await news({
        begin_date: date[0],
        end_date: date[1]
      })
      this.list = res.data.content.filter(el => el.id == this.$route.query.id)
      this.newsmsg = JSON.parse(JSON.stringify(this.list[0].content))
      this.title = this.list[0].title

      //修改图片服务器上的路径
      let div = document.createElement('div')
      div.innerHTML = this.newsmsg
      let img = div.querySelectorAll('img')
      img.forEach(item => {
        item.src = 'http://weixin.nhdyyy.cn/weixin' + item.src.slice(30)
      })
      let all = div.querySelectorAll('*')
      all.forEach(el => {
        if (el.style.backgroundImage == '') {
          el.style.backgroundImage
        }
        else {
          if (el.style.backgroundImage != 'initial') {
            el.style.backgroundImage = 'url(http://weixin.nhdyyy.cn/weixin' + el.style.backgroundImage.slice(5, -2) + ')'
          }
        }
      })
      this.$refs.neirong.innerHTML = div.innerHTML

      this.$refs.bscroll.$el.firstChild.style.overflow = 'hidden'

      this.H = `calc(${document.documentElement.clientHeight / this.ratio}px)`
      this.W = `calc(${window.screen.width / this.ratio}px)`
      setTimeout(() => {
        this.$refs.bscroll.refresh()
      }, 300);
    },
  },

}
</script>

<style lang="less" scoped>
.dialogItem_r_phone {
  // overflow-y: auto;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-color: @cardColor;
}
.dialogItem_r_phone_mn {
  background: @cardColor;
  transform-origin: 0 0;
  overflow: hidden;
  position: relative;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  // 下面两句css配合js中scrollX: true 实现预览是否可以左右拖动
  // white-space:nowrap;
  // .er{
  // display:inline-block;
  // }
}
</style>